<template>
  <v-app>
    <v-main class="white">
      <header-info />
      <section class="info__dishe">
        <v-row justify="center">
           <v-col cols="12" sm="12" md="6" lg="3" :class="{'pa-3': $vuetify.breakpoint.mdAndUp, 'pa-0': !$vuetify.breakpoint.mdAndUp}">
              <v-card class="text-center" outlined >
                 <v-img
                  :height="imgDishe"
                  :src="image_url"
                  :lazy-src="image_url"
                  class="white--text"
                  width="100%"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-title class="title_dishe text-center pa-3 d-block" v-text="name_dishe"/>
                <v-card-text  class="description_dishe pa-3" v-html="description_dishe"></v-card-text>
                <!-- ALERGENOS -->
                <v-row justify="center" no-gutters>
                  <v-col cols="12" class="text-center py-3">
                    <v-avatar class="mx-1" size="45" v-for="(allergen,i) in detailsDashe.allergens" :key="i">
                      <img :src="url_allergen_img + allergen.img">
                    </v-avatar>
                  </v-col>
                </v-row>

                <v-row justify="center" no-gutters>
                  <v-col cols="12" class="text-center pb-0">
                    <p class="title_price" v-text="$t('default.plato.precio')"></p>
                  </v-col>
                  <v-col cols="12">
                      <template
                        v-for="(p,key) in prices">
                        <div :key="key" class="text-center">
                        <strong>{{p.name}}</strong><br>
                        <v-chip-group mandatory active-class="primary--text" class="d-none d-md-block" >
                          <div style="margin: auto">
                            <template v-for="(detail, idx) in filterPrice(p.prices)"
                              >
                              <v-chip :key="idx"
                                label>
                                {{ formatNumber(detail.amount,coinCountry[detail.coin]) }}
                              </v-chip>
                            </template>
                          </div>
                        </v-chip-group>
                        </div>
                        <div :key="key" class="d-md-none px-3 pb-1 text-center">
                          <template v-for="detail in filterPrice(p.prices)"
                              >
                              {{ formatNumber(detail.amount,coinCountry[detail.coin]) }}
                          </template>
                        </div>
                      </template>
                    <!-- <addToCard :item="detailsDashe" :type="'dishe'"></addToCard> -->
                  </v-col>


                </v-row>
              </v-card>
           </v-col>
        </v-row>
        <v-row justify="center" no-gutters :class="{'my-8' : $vuetify.breakpoint.mdAndUp}">
          <v-col cols="10" class="text-center py-3">
            <base-divider dense color="" max-width="100%" space="0" />
          </v-col>
          <v-col cols="10" class="text-center pb-3">
            <p class="title_price" v-text="$t('default.plato.promociones')"></p>
          </v-col>
        </v-row>
        <!-- IMAGENES PROMOCIONES -->
        <v-row no-gutters v-if="this.promotions.length <0">

          <template v-if="$vuetify.breakpoint.smAndDown">
            <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '6'" :class="$vuetify.breakpoint.mdAndUp ? 'pr-6' : 'px-4'">
              <p class="title_promotion landing_primary--text">{{ list_promotions.name | traslate($store.getters.language) }}</p>
              <v-card class="mt-0 card-letter" flat>
                <v-img class="" :src="url_img+'images/promotions/'+list_promotions.image" :lazy-src="url_img+'images/promotions/'+list_promotions.image" aspect-ratio="1.4">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                </template>
                </v-img>
              </v-card>
            </v-col>
          </template>
          <template v-else>
            <template v-for="(item, i) in list_promotions">
              <v-col cols="4" v-if="i < 3" :key="i">
                <p class="title_promotion landing_primary--text px-8">{{ item.name | traslate($store.getters.language) }}</p>
                <v-card class="mt-0 card-letter px-8" flat>
                  <v-img class="" :src="url_img+'images/promotions/'+item.image" :lazy-src="url_img+'images/promotions/'+item.image" aspect-ratio="1.4" :max-height="imgPromotion">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                  </template>
                  </v-img>
                </v-card>
              </v-col>
            </template>
          </template>
        </v-row>

      </section>
    </v-main>
   <v-overlay :value="overlay" :opacity="0.99" :z-index="10" color="white" class="text-center">
      <v-img
        contain
        max-width="120"
        :src="require('@/assets/Todo-carta-logo.png')"
      ></v-img>
      <v-progress-linear
          color="blue-grey lighten-3"
          indeterminate
          rounded
          height="4"
        ></v-progress-linear>
    </v-overlay>
  </v-app>
</template>

<script>
  import { mapState, mapGetters } from 'vuex'
  import HeaderInfo from './components/HeaderInfo'
  import coinSymbol from "../../mixins/coinSymbol"
  import coinCountry from "../../mixins/coinCountry"
  // import addToCard from './components/AddToCard'
  const os = require('os');
  export default {
    name: 'Dishe',
    components: { HeaderInfo },
    head() {
      return{
        title: this.name_restaurant,
        link: [
          { rel: 'icon', type: 'image/x-icon', href: this.logo }
        ],
        meta: [
          { name: 'description', content: 'Todo cartas online es un application dirigida a restaurantes la cual contiene un módulo de carta electrónica donde se puede consultar la carta desde cualquier dispositivo, tus clientes pueden ver la carta de tu restaurante desde sus propios dispositivos móviles. No hace falta que los clientes instalen nada en su dispositivo, solo leerán un código QR y ya tienen disponible la carta.'},
          { property: 'og:title', content: this.name_restaurant + ' - Todo Cartas Online'},
          { property: 'og:site_name', content: 'Todo Cartas Online'},
          { property: 'og:description', content: 'Restaurante ' + this.name_restaurant + ' en Todo Cartas Online'},
          {property: 'og:type', content: 'web'},
          {property: 'og:url', content: 'https://' + os.hostname() + '/r/' + this.detailsDashe.url},
          {property: 'og:image', content: this.logo }
        ]
      }
    },
    data: () => ({
      detailsDashe: [],
      url_img: process.env.VUE_APP_IMG_URL_API,
      url_allergen_img: process.env.VUE_APP_IMG_ALERGENOS,
      promotions: [],
      i: '',
      coins: []
    }),
    created(){
      this.getDishe();

    },
    mounted() {
    },
    methods:{
      async getDishe(){
        const url = this.$route.params.landing ? this.$route.params.landing : '';
        const id = this.$route.params.id ? this.$route.params.id : '';
        this.$store.commit('SET_OVERLAY', true);
        try{
          const {data} = await this.$api.get(`api/restaurant/${url}/dishes/${id}`);
          const color = JSON.parse(data.data.restaurant.restaurant_setup.colors);
          this.$store.commit('landing/SET_CONFIG', data.data.restaurant);
          this.$store.commit('landing/SET_COLOR', color.default);
          this.$vuetify.theme.themes.light.landing_primary = color.default;
          this.detailsDashe = data.data.dish;
          this.promotions = data.data.restaurant.promotions;
          this.$store.commit('SET_OVERLAY', false);
          this.coins = JSON.parse(this.$store.getters["landing/coins"])
        }
        catch(e){
          if(e.response.data.error_code == '400'){
            this.$store.commit('SET_OVERLAY', false);
            this.$router.push({name: '404', query: {msj: e.response.data.message}})
          }
          else{
            this.$store.commit('SET_OVERLAY', false);
            this.$router.push({name: '404'})
          }
        }
      },
      barajar(array) {
        var currentIndex = array.length;
        var temporaryValue, randomIndex;

        // En cada iteración disminuimos el currentIndex, por lo que el bucle se realiza
        // mientras sea diferente que 0
        while (0 !== currentIndex) {

            // Elige una posicion del array aleatoriamente
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // Ponemos el elemento seleccionado en la ultima posición del array (currentIndex)
            // para que no puede volver a ser seleccionado
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
      },
      formatNumber(val, country){
        this.$numeral.locale(country)
        return this.$numeral(val).format('0,0[.]00 $')
      },
      filterPrice(price){
        var prices = Object.values(price)
        var exists = false;
        for (let a = 0; a < prices.length; a++) {
          const price = prices[a];
          for (let e = 0; e < this.coins.length; e++) {
            const coins = this.coins[e];
            exists = false;
            if (price.coin == coins.cod) {
              exists =  true;
              break;
            }
          }
          if (!exists) {
            prices.splice(a,1);
          }
        }
        return prices;
      }

    },
    computed: {
       ...mapGetters({
        language: 'language',
        logo: 'landing/logo',
        image_header: 'landing/header',
        name_restaurant: 'landing/name',
        address: 'landing/address',
        phone: 'landing/phone',
        email: 'landing/email',
        lat: 'landing/lat',
        lng: 'landing/lng',
        url_allergen: 'landing/url_allergen'
      }),
      ...mapState(['overlay']),
      imgDishe(){
        const height = this.$vuetify.breakpoint.mdAndUp ? '50vh' : '100%'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
      imgPromotion(){
        const height = this.$vuetify.breakpoint.mdAndUp ? '35vh' : '40vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
      name_dishe(){
        if(this.detailsDashe.name)
          return this.detailsDashe.name[this.$store.getters.language] ? this.detailsDashe.name[this.$store.getters.language] : this.detailsDashe.name.es;
        return '';
      },
      description_dishe(){
        if(this.detailsDashe.description)
          return this.detailsDashe.description[this.$store.getters.language] ? this.detailsDashe.description[this.$store.getters.language] : this.detailsDashe.description.es;
        return '';
      },
      image_url(){
        return this.detailsDashe.image_dish ? `${this.url_img}images/dishes/${this.detailsDashe.image_dish}` : '';
      },
      list_promotions(){
        if(this.$vuetify.breakpoint.smAndDown){
          return this.promotions[Math.floor(Math.random()* this.promotions.length)]
        }

        return this.barajar(this.promotions);
      },
      prices() {
        if(this.detailsDashe.price === undefined) return []
        let price = JSON.parse(this.detailsDashe.price);
        return price;
      },
      coinSymbol() {
        return coinSymbol
      },
      coinCountry() {
        return coinCountry
      },
    },
    filters:{
      traslate(val, lang){
        return val[lang] ? val[lang] : val.es;
      }
    }
  }
</script>
<style lang="sass">
  .icon-left
    margin-left: -4px
    margin-right: 8px

  .info__dishe
    margin-bottom: 2rem
    & .title_dishe
      padding: 15px 0px
      font-size: 1.2rem
      letter-spacing: 1px
      font-weight: 600
    & .description_dishe
      color: #666666
      text-align: center
      margin-bottom: 0px !important
    & .price_dishe
      font-weight: 600
      font-size: 1.4rem
    & .title_price
      color: #7F7F7F
      margin-bottom: 0px !important
      font-weight: 500
      font-size: 1.4rem


</style>
